import { ComposableClient } from "@becomposable/client";
import { AuthTokenPayload } from "@becomposable/common";
import { createContext, useContext } from "react";

import Env from '@/env';
import * as Sentry from '@sentry/react';
import { jwtDecode } from "jwt-decode";
import { TypeRegistry } from "./TypeRegistry";
import { getComposableToken } from "./auth/composable";
import { firebaseAuth } from "./auth/firebase";

export const LastSelectedAccountId_KEY = 'composableai.lastSelectedAccountId';
export const LastSelectedProjectId_KEY = 'composableai.lastSelectedProjectId';


class UserSession {

    isLoading = true;
    client: ComposableClient;
    authError?: Error;
    authToken?: AuthTokenPayload;
    typeRegistry?: TypeRegistry;
    setSession?: (session: UserSession) => void;
    lastSelectedAccount?: string | null;
    lastSelectedProject?: string | null;

    constructor(client?: ComposableClient, setSession?: (session: UserSession) => void) {

        if (client) {
            this.client = client;
        } else {
            this.client = new ComposableClient({
                serverUrl: Env.endpoints.studio,
                storeUrl: Env.endpoints.zeno,
            });
        }

        if (setSession) {
            this.setSession = setSession;
        }

        this.logout = this.logout.bind(this);
    }

    get store() {
        return this.client.store;
    }

    get user() { //compatibility
        return this.authToken;
    }

    get account() { //compatibility
        return this.authToken?.account;
    }

    get project() {
        return this.authToken?.project;
    }

    get accounts() { //compatibility
        return this.authToken?.accounts;
    }

    get authCallback() {
        return this.rawAuthToken.then(token => `Bearer ${token}`);
    }

    get rawAuthToken() {
        return getComposableToken().then(res => {
            const token = res?.rawToken
            if (!token) {
                throw new Error('No token available');
            }
            this.authToken = jwtDecode(token) as unknown as AuthTokenPayload;
            return token;
        });
    }

    signOut() { //compatibility
        this.logout();
    }

    getAccount() {
        return this.authToken?.account;
    }

    async login(token: string) {
        this.authError = undefined;
        this.isLoading = false;
        this.client.withAuthCallback(() => this.authCallback)
        console.log('Auth: token received in ', token);
        this.authToken = jwtDecode(token) as unknown as AuthTokenPayload;
        console.log(`Logging in as ${this.authToken?.name} with account ${this.authToken?.account.name} (${this.authToken?.account.id}, and project ${this.authToken?.project?.name} (${this.authToken?.project?.id})`);

        //store selected account in local storage
        localStorage.setItem(LastSelectedAccountId_KEY, this.authToken.account.id);
        Sentry.setUser({ id: this.authToken.sub, email: this.authToken.email });
        await this._loadTypes();

        return Promise.resolve();

    }

    isLoggedIn() {
        return !!this.authToken;
    }


    logout() {
        console.log('Logging out');
        if (this.authToken) {
            firebaseAuth.signOut();
        }
        this.authError = undefined;
        this.isLoading = false;
        this.authToken = undefined;
        this.typeRegistry = undefined;
        this.setSession = undefined;
        this.client.withAuthCallback(undefined);
        localStorage.removeItem(LastSelectedAccountId_KEY);
    }

    async switchAccount(targetAccountId: string, targetProjectId?: string) {

        console.log('Switching to account: ', targetAccountId, this)
        localStorage.setItem(LastSelectedAccountId_KEY, targetAccountId)
        localStorage.setItem(LastSelectedProjectId_KEY, targetProjectId ?? '')
        window.location.replace('/'); // reload the app

    }

    async switchProject(projectId: string) {
        console.log('Now switching to project: ' + projectId, this);
        localStorage.setItem(LastSelectedProjectId_KEY, projectId)
        window.location.replace('/'); // reload the app

        /*return getComposableToken(getFirebaseAuthToken, this.account!.id ?? undefined, projectId, true).then(res => {
            console.log('Auth: fetched user token from studio', res.token);
            const token = res?.token;
            if (!token) {
                throw new Error('No token available');
            }

            if (!token.accounts || token.accounts.length === 0) {
                throw new Error('No accounts available');
            }

            return this.login(res.rawToken).then(() => {
                this.setSession?.(this.clone())
            });
        });*/

    }

    async _loadTypes() {
        if (this.project) {
            return this.store.types.layouts().then(types => this.typeRegistry = new TypeRegistry(types)).catch(err => {
                console.error('Failed to fetch object types', err);
                throw err;
            })
        } else {
            console.log('No project selected');
        }
    }

    async reloadTypes() {
        return this._loadTypes().then(() => {
            this.setSession?.(this.clone());
        });
    }

    clone() {
        const session = new UserSession(this.client);
        session.isLoading = this.isLoading;
        session.authError = this.authError;
        session.authToken = this.authToken;
        session.setSession = this.setSession;
        session.lastSelectedAccount = this.lastSelectedAccount;
        session.switchAccount = this.switchAccount;
        session.typeRegistry = this.typeRegistry;
        return session;
    }
}

const UserSessionContext = createContext<UserSession>(undefined as any);

export function useUserSession() {
    const session = useContext(UserSessionContext);

    if (!session) {
        throw new Error('useUserSession must be used within a UserSessionProvider');
    }
    return session;
}

export { UserSession, UserSessionContext };
